
  import { Component, Vue } from 'vue-property-decorator';
  import ReferralCodeEdit from '@/components/ReferralCodeEdit.vue';
  import { CREATE_OR_UPDATE_REFERRAL_CODE, FETCH_REFERRAL_CODE, RESET_REFERRAL_CODE_ITEM } from '@/store/referralCodes';

  @Component({
    components: { ReferralCodeEdit }
  })
  export default class ReferralCodeCreate extends Vue {
    public created() {
      this.$store.commit(RESET_REFERRAL_CODE_ITEM);

      if (this.$route.params.id) {
        this.$store.dispatch(FETCH_REFERRAL_CODE, this.$route.params.id);
      }
    }

    public save() {
      (this.$refs.referralCodeEdit as any).$v.$touch();

      if ((this.$refs.referralCodeEdit as any).$v.$error) {
        return;
      }

      this.$store.dispatch(CREATE_OR_UPDATE_REFERRAL_CODE)
        .then(() => this.$router.push({ name: 'app.referralCodes.list' }));
    }
  }
