
  import InspiniaIbox from '@/components/InspiniaIbox.vue';
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import { ReferralCode } from '@/types/entities';
  import { validationMixin } from 'vuelidate';
  import { required } from 'vuelidate/lib/validators';
  import { mapMutations } from 'vuex';
  import HipsterTextarea from '@/components/HipsterTextarea.vue';
  import HipsterInput from '@/components/HipsterInput.vue';
  import { SET_REFERRAL_CODE_ITEM_VALUE } from '@/store/referralCodes';

  @Component({
    components: { HipsterTextarea, InspiniaIbox, HipsterInput },
    mixins: [ validationMixin ],
    validations() {
      return {
        referralCode: {
          code: { required },
          description: { required }
        }
      };
    },
    methods: {
      ...mapMutations({
        setReferralCodeValue: SET_REFERRAL_CODE_ITEM_VALUE
      })
    }
  })
  export default class ReferralCodeEdit extends Vue {
    @Prop() referralCode: ReferralCode;
  }
