
  import { Component, Prop, Vue } from 'vue-property-decorator';

  let timeout: any = null;

  @Component({})
  export default class HipsterTextarea extends Vue {
    @Prop() value: string;
    @Prop() label: string;
    @Prop({ default: 0 }) timeout?: number;

    onChanged(eventName: string, value: any) {
      if (this.timeout) {
        clearTimeout(timeout);

        timeout = setTimeout(() => this.$emit(eventName, value), 500);
      }

      this.$emit(eventName, value);
    }
  }
